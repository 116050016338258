import colorSharp from "../assets/img/color-sharp.png";

// Import Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper";

export const Skills = () => {
  return (
    <section className="skill" id="Habilidades">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Habilidades</h2>
              <p>
                Estas son algunas de las habilidades que aprendí en este tiempo
                como desarrollador.<br></br> Proximamente este slider sera
                interminable.
              </p>

              <Swiper
                loop={true}
                autoplay={{
                  delay: 1000,
                  disableOnInteraction: false,
                }}
                centeredSlides={false}
                breakpoints={{
                  0: {
                    slidesPerView: 3,
                  },
                  464: {
                    slidesPerView: 4,
                  },
                  1024: {
                    slidesPerView: 5,
                  },
                  1280: {
                    slidesPerView: 7,
                  },
                }}
                modules={[Autoplay]}
              >
                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original-wordmark.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original-wordmark.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/php/php-original.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original-wordmark.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/laravel/laravel-plain-wordmark.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original-wordmark.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nextjs/nextjs-original.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nestjs/nestjs-plain-wordmark.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original-wordmark.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original-wordmark.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-original-wordmark.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original-wordmark.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/less/less-plain-wordmark.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original-wordmark.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sequelize/sequelize-original-wordmark.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original-wordmark.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original-wordmark.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jquery/jquery-original-wordmark.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/linux/linux-original.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/wordpress/wordpress-original.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/woocommerce/woocommerce-original-wordmark.svg"
                    className="md:h-40 h-24"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  );
};
