import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import nomad from '../assets/img/nomad.png'
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import emailjs, { send } from '@emailjs/browser'

export const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Enviar');
  const [status, setStatus] = useState({});

  // console.log(formDetails)

  const onFormUpdate = (category, value) => {
      setFormDetails(prevState=>{
        const newState = {
          ...prevState,
          [category]:value
        }
        return newState;
      })
  }

  const handleSubmit = async (e)=>{
    e.preventDefault()
    if(formDetails.firstName && formDetails.lastName && formDetails.email && formDetails.phone && formDetails.message){
      setButtonText("Enviando...");
      const sending = await emailjs.sendForm('service_sdc0qbi','template_456z76w', e.target, 'ieSwzhVvXqz-SsLho');
  
      setButtonText("Enviar");
  
      const enviado = await sending
  
      setFormDetails(formInitialDetails);
  
      if(enviado.status == 200){
        setStatus({ success: true, message: 'Mensaje enviado con éxito.'});
      } else{
        setStatus({ success: false, message: 'Algo salio mal, intentalo de nuevo más tarde.'});
      }
    }
    else{
      setStatus({ success: false, message: 'No pueden haber campos vacíos.'});
    }

  }

  return (
    <section className="contact" id="Contacto">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={nomad} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Ponete en contacto</h2>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" name="user_name" value={formDetails.firstName} placeholder="Nombre" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" name="user_lastname" value={formDetails.lastName} placeholder="Apellido" onChange={(e) => onFormUpdate('lastName', e.target.value)}/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" name="user_email" value={formDetails.email} placeholder="Correo electronico" onChange={(e) => onFormUpdate('email', e.target.value)} />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel" name="user_phone" value={formDetails.phone} placeholder="Movil" onChange={(e) => onFormUpdate('phone', e.target.value)}/>
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" name="user_message" value={formDetails.message} placeholder="Mensaje" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                      
                      {status.message &&
                      <>
                      <Col size={12} className="px-1">
                        <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                      </Col></>
                    }
                      
                      <button type="submit"><span>{buttonText}</span></button>
                    </Col>

                  </Row>
                </form>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
