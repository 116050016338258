import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
//Prof
import winpax from "../assets/img/winpax.jpg";
import aptoclick from "../assets/img/aptoclick.jpg";
// Imagenes proyectos henry
import todoApp from "../assets/img/todoApp.jpg";
import proyectoFinal from "../assets/img/proyectoFinal.jpg";
import proyectoIndividual from "../assets/img/proyectoIndividual.jpg";
// Imagenes proyectos personales
import freelance from "../assets/img/freelance.jpg";
import frontendStore from "../assets/img/frontendStore.jpg";
import blogCafe from "../assets/img/blogCafe.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const professional = [
    {
      title: "Winpax",
      description: "Laravel - Lumen - Docker - MySQL",
      imgUrl: winpax,
      link: "https://www.winpax.com.ar/",
    },
    {
      title: "Aptoclick",
      description: "Laravel - Next.js - NestJS - AWS - MySQL",
      imgUrl: aptoclick,
      link: "https://aptoclick.com/",
    },
  ];

  const bootcamp = [
    {
      title: "To-Do App",
      description: "React.js - Redux",
      imgUrl: todoApp,
      link: "https://dabarrio-todo-app.netlify.app/",
    },
    {
      title: "Proyecto individual",
      description:
        "React.js - Redux - CSS - Node.js - Express - PostgreSQL - Sequalize",
      imgUrl: proyectoIndividual,
      link: "https://dabarrio-pi-dogs.vercel.app/",
    },
    {
      title: "Proyecto grupal",
      description:
        "React.js - Redux - Tailwind - Node.js - Express - PostgreSQL - Sequalize - SCRUM",
      imgUrl: proyectoFinal,
      link: "https://pg-rgb-store-three.vercel.app/",
    },
  ];

  const others = [
    {
      title: "Dev Freelance",
      description: "HTML - CSS",
      imgUrl: freelance,
      link: "https://freelancer-db.netlify.app/ ",
    },
    {
      title: "Frontend Store",
      description: "HTML - CSS",
      imgUrl: frontendStore,
      link: "https://frontedstore-db.netlify.app/",
    },
    {
      title: "Blog Café",
      description: "HTML - CSS",
      imgUrl: blogCafe,
      link: "https://blogcafe-db.netlify.app/",
    },
  ];

  return (
    <section className="project" id="Proyectos">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Mis proyectos</h2>
                  <br />
                  <br />

                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          {" "}
                          Proyectos Profesionales
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          Proyectos Bootcamp
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Otros proyectos</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row className="d-flex justify-content-center">
                          {professional.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {bootcamp.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                      <Row>
                          {others.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
