import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import programer from '../assets/img/programer_banner.png'
import DavidBarriosCV from '../assets/DavidBarriosCV.pdf'
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "FullStack", "Frontend", "Backend" ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="Inicio">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span className="tagline">Bienvenidxs a mi Portfolio!</span>
                <h1>{`Hola, soy David Barrios`} <span className="txt-rotate" dataperiod="1000" data-rotate='[ "Web Developer", "Web Designer", "UI/UX Designer" ]'>
                  <br></br><span id="dev_banner" className="wrap">{`Desarrollador ${text}`}</span></span></h1>
                  <p>Soy un joven hincha de River, fan de los Simpsons, apasionado por la tecnología, padre de dos niños inquietos pero adorables y con muchas ganas de aprender.
                  <br></br>
                   Me defino como una persona que se encuentra en continuo aprendizaje. En cuanto al desarrollo de software, me inclino más hacia el lado del frontend, si bien el backend también me gusta mucho.
                  <br></br>
                   <span><a href="#Contacto">Contactame</a></span> o descarga mi CV aquí abajo.</p>
                  <a href={DavidBarriosCV} download="DavidBarriosCV"><button> Descargar CV <ArrowRightCircle size={25} /></button></a>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={programer} alt="Programer Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
